import _objectSpread from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { resourceTypeCheckboxType } from "../../assets/js/data";
import SearchSelect from "../../components/common/Search";
import Common from '../../assets/js/common';
import { uploadFile } from '../../api/qiniuApI';
export default {
  name: "FeedbackList",
  data: function data() {
    return {
      expands: [],
      //要展开的行，数值的元素是row的key值
      filterArr: [],
      //筛选的方法
      replyList: [],
      //回复内容的列表
      feedbackDataContent: "",
      //当前回复的内容
      urlImageArr: [],
      //当前回复的内容图片
      fileList: [//测试
      ],
      // 筛选
      filter: [{
        placeholder: '意见反馈状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '回复状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '已回复'
        }, {
          id: '0',
          name: '未回复'
        }]
      }, {
        placeholder: '意见反馈关键字',
        value: '',
        type: 'text',
        list: []
      }],
      sum: "",
      //类型循环的所有id加起来
      resourceTypeCheckboxType: resourceTypeCheckboxType,
      resourceTypeCheckboxTypeList: _toConsumableArray(resourceTypeCheckboxType.list),
      //类型循环的数据
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: "rtl",
      drawerState: "",
      drawerData: {
        name: "",
        code: "",
        rank: "",
        state: 1,
        createdAt: "",
        type: "" //类型
      },

      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false // 删除弹框显示隐藏
    };
  },

  watch: {},
  components: {
    SearchSelect: SearchSelect
  },
  created: function created() {
    //获取数据
    this.initData();
  },
  methods: {
    disableFn: function disableFn() {
      this.$toast.error("不可点击哦！");
    },
    getRowKeys: function getRowKeys(row) {
      return row.id;
    },
    zkFn: function zkFn(val) {
      this.expands = [];
      this.expands.push(val.id);
    },
    isIndexOf: function isIndexOf(id, arr) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] == id) {
          return id;
        }
      }
      return -1;
    },
    //回复
    submitFeedback: function submitFeedback(data) {
      if (this.fileList.length > 0) {
        console.log("uploadImage", data);
        this.uploadImage(data);
      } else {
        this.feedbackAddReply(data);
      }
    },
    //修改状态
    feedbackAddReply: function feedbackAddReply(jsonData) {
      var _this2 = this;
      // console.log("jsonData",jsonData)
      /*
      * userId	T文本	是
        roleId	T文本	是
        id	T文本	是
        replyContent	T文本	是
        replyFiles	T文本	是
        state
      * */
      // this.$toast.success("请求接口成功")
      // return;
      this.service.axiosUtils({
        requestName: "feedbackAddReply",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: jsonData.id,
          replyContent: this.feedbackDataContent,
          replyFiles: this.urlImageArr
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this2.$toast.success(res.message);

            //内容初始化
            _this2.feedbackDataContent = "";
            //图片初始化
            _this2.urlImageArr = [];
            _this2.fileList = [];

            //重新加载
            _this2.initData();
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    //修改
    feedbackEditReply: function feedbackEditReply(jsonData, state) {
      var _this3 = this;
      // console.log("jsonData",jsonData)
      /*
      * userId	T文本	是
        roleId	T文本	是
        id	T文本	是
        replyContent	T文本	是
        replyFiles	T文本	是
        state  1启用 -1禁用
      * */
      // this.$toast.success("请求接口成功")
      // return;
      this.service.axiosUtils({
        requestName: "feedbackAddReply",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: jsonData.id,
          replyContent: jsonData.replyContent,
          replyFiles: this.getImageUrl(jsonData.rlyfiles),
          state: state
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this3.$toast.success(res.message);
            //重新加载
            _this3.initData();
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    // 图片上传
    uploadImage: function uploadImage(data) {
      var _this4 = this;
      var dateTime = new Date().getTime();
      var _this = this;
      //element ui对象转为原生file
      this.addfileList = this.cloneFile(this.fileList);
      var _loop = function _loop(i) {
        var file = _this4.addfileList[i];
        uploadFile({
          folderName: "feedback",
          dateTime: dateTime,
          //唯一值
          duration: file.duration,
          //唯一值
          isHideLoading: true,
          //上传过程中是否显示遮罩层
          complete: function complete(res, url) {
            _this.urlImageArr.push(url);
            if (_this.addfileList.length == _this.urlImageArr.length) {
              _this.feedbackAddReply(data);
            }
            // console.log(props.formFile)

            /*_this.formFile.url=url;
            //普通文件
            let json={
              url:url,
              size:res.size,
              name:res.name
            }
            //上传完成file数组
            _this.fileArr.push(json)
            _this.formFile.urlList.push(json);
            */
            //如果全部都传完了的话就添加到数据库
            // console.log(_this.addfileList.length,_this.fileArr.length)
            // return;
            /*if(_this.addfileList.length ==  _this.fileArr.length){
              _this.$emit("confirm",formEl)
              //关闭限制不可点击的按钮状态
              _this.isFlag=false;
              //开启上传url的状态
              _this.subbmitState=true;
            }*/
          },
          //进度
          next: function next(res, subscription) {
            /* let json={
               name:"name",
               total:res.total
             }
             _this.fileListProgress[i]=json;*/
            _this.subscription = subscription;
            var percent = res.total.percent;
            _this.addfileList[i].percent = Math.floor(percent * 100) / 100;
            // console.log("_this.addfileList[i].percent",_this.addfileList[i].percent)
          }
        }, file.raw);
      };
      for (var i = 0; i < this.addfileList.length; i++) {
        _loop(i);
      }
    },
    //资源改变
    changeFile: function changeFile(file, fileList) {
      // this.addfileList=this.cloneFile(fileList);
      // console.log(this.addfileList,this.addfileList)
    },
    //element ui对象转为原生file
    cloneFile: function cloneFile(fileList) {
      var arr = [];
      for (var i = 0; i < fileList.length; i++) {
        var file = fileList[i];
        var json = _objectSpread({}, fileList[i]);
        json.percent = -1;
        arr.push(json);
      }
      return arr;
    },
    //获取反馈的图片
    getImageUrl: function getImageUrl(arrs) {
      var arr = [];
      for (var i = 0; i < arrs.length; i++) {
        arr.push(arrs[i].fileUrl);
      }
      return arr;
    },
    //筛选功能
    search: function search(filterArr) {
      this.filterArr = filterArr;
      /* this.service.axiosUtils({
         requestName: "feedbackGetList",
         data: {
           state:filterArr[0].value,
           isReply:filterArr[1].value,
           name:filterArr[2].value,
           // userId:this.userInfo.userId,
           // roleId:this.userInfo.roleId,
           pageSize: this.pageSize,
           pageIndex: this.currentPage,
         },
         sucFn: (res) => {
           if (res.status === 1) {
             this.tableData = res.data.data;
             this.total = res.data.total;
             this.initType();
           } else {
             this.$message.error(res.message);
           }
         },
       });*/
      this.initData();
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.filter[2].value = "";
      this.initData();
    },
    //类型选择有改变的时候
    checkedItem: function checkedItem(value) {
      console.log(this.drawerData);
      // this.resourceTypeCheckboxTypeList[]
    },
    // 添加函数
    addFun: function addFun() {
      // this.clearDrawerData();
      this.initType();
      this.drawerState = "add";
      this.drawer = true;
    },
    filterData: function filterData() {},
    // 初始化数据
    initData: function initData() {
      var _this5 = this;
      var postData = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId,
        pageSize: this.pageSize,
        pageIndex: this.currentPage
      };
      if (this.filter[0].value == 1 || this.filter[0].value == -1) {
        postData.state = parseInt(this.filter[0].value);
      }
      if (this.filter[1].value == 1 || this.filter[1].value === "0") {
        postData.isReply = parseInt(this.filter[1].value);
      }
      if (this.filter[2].value != "") {
        postData.keyword = this.filter[2].value;
      }
      this.service.axiosUtils({
        requestName: "feedbackGetList",
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this5.tableData = res.data.data;
            _this5.total = res.data.total;
            _this5.initType();
          } else {
            _this5.$message.error(res.message);
          }
        }
      });
    },
    //新建初始化数据
    clearDrawerData: function clearDrawerData() {
      this.drawerData = {
        name: "",
        code: "",
        rank: "",
        state: "",
        type: ""
      };
      this.initType();
      this.$refs.form.resetFields();
    },
    //初始化type
    initType: function initType() {
      //判断选中的类型
      this.sum = 0;
      for (var i = 0; i < this.resourceTypeCheckboxType.list.length; i++) {
        var id = this.resourceTypeCheckboxType.list[i].id;
        this.sum += id;
        //初始化数据
        this.resourceTypeCheckboxTypeList[i].isChecked = true;
      }
      this.drawerData.type = this.sum;
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this6 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "topictypeChangeState",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this6.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this6.$toast.error(res.message);
          }
        }
      });
    },
    // table-中操作函数
    detail: function detail(type, val) {
      if (type === "reply") {
        //回复
        this.zkFn(val);
      } else if (type === "edit") {
        //改状态

        var state = 1;
        if (val.state == 1) {
          state = -1;
        } else {
          state = 1;
        }
        this.feedbackEditReply(val, state);
      } else {
        //删除
        this.centerDialogVisible = true;
        this.delId = val.id;
      }
    },
    // drawer抽屉-取消函数
    cancelClick: function cancelClick() {
      /*      this.$nextTick(function () {
              this.$refs["form"].resetFields();
            });*/
      this.$refs["form"].resetFields();
      this.drawer = false;
    },
    //传递的参数处理转换
    setPostData: function setPostData() {
      this.drawerData.type = 0;
      for (var i = 0; i < this.resourceTypeCheckboxTypeList.length; i++) {
        if (this.resourceTypeCheckboxTypeList[i].isChecked == true) {
          this.drawerData.type += this.resourceTypeCheckboxTypeList[i].id;
        }
      }
    },
    // drawer抽屉-确定函数
    confirmClick: function confirmClick(formName) {
      var that = this;
      this.setPostData();
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = "";
          that.drawerData.userId = that.userInfo.userId;
          that.drawerData.roleId = that.userInfo.roleId;
          if (that.drawerState === "edit") {
            //修改
            url = "topictypeEditTopicType";
          } else {
            //添加
            url = "topictypeAddTopicType";
          }
          that.service.axiosUtils({
            requestName: url,
            data: that.drawerData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.clearDrawerData();
                if (that.drawerState === "add") {
                  that.currentPage = 1;
                }
                that.$nextTick(function () {
                  that.$refs["form"].resetFields();
                });
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this7 = this;
      //删除当前的分类
      this.service.axiosUtils({
        requestName: "feedbackDeleteReply",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this7.$toast.success(res.message);
            _this7.delId = "";
            _this7.centerDialogVisible = false;
            //内容初始化
            _this7.feedbackDataContent = "";
            //图片初始化
            _this7.urlImageArr = [];
            _this7.fileList = [];
            //重新加载
            _this7.initData();
          } else {
            _this7.$toast.error(res.message);
          }
        }
      });
    }
  }
};